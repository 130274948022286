import React from "react"
import {
  Container,
  Heading,
  Row,
  Box,
  ReadMoreLink,
  TickListItem
} from "@life-without-barriers/react-components"
import { Link } from "gatsby"

const QldWhatCanIExpect = () => (
  <Container>
    <Row>
      <Box className="layout-readable">
        <Heading size={2}>What can I expect as a carer?</Heading>
        <p>
          Fostering a child in Queensland with Life Without Barriers means you
          will receive training, help and support from day one of your journey.
        </p>
      </Box>
    </Row>
    <Row>
      <Box>
        <div className="mt4 layout-columns">
          <div>
            <Heading size={3} topMargin="none" className="color-lwb-theme-dark">
              Training
            </Heading>
            <p>
              In Queensland, Foster Carers are required to complete pre-service
              training before they commence, and some mandatory training modules
              during the first two years you are an approved Carer. This
              training is optional for Kinship Carers. Life Without Barriers
              offers all Carers a range of learning opportunities suited to your
              interests, your experience and the needs of children and young
              people living with you. This includes access to a large range of
              eLearning options such as child development, understanding trauma
              and stress, and healthy living. There are also group training
              options and conferences you might like to participate in.
            </p>
          </div>
          <div>
            <Heading size={3} className="color-lwb-theme-dark">
              Support
            </Heading>
            <p>
              Fostering children in Queensland with Life Without Barriers means
              you are never alone in your journey. It can be difficult to
              understand some of the experiences children bring with them, so
              it’s important that as a carer you feel supported and confident in
              your role. You will have access to 24/7 support for advice and
              direction in challenging situations. Also, our specialist staff
              work with children and carers where additional support is needed.
            </p>
            <p>
              Aside from the support Life Without Barriers will provide, all
              Queensland Carers can access Queensland Foster and Kinship care
              for advocacy, information and support. Visit them here -{" "}
              <Link to="https://qfkc.com.au/">
                Queensland Foster and Kinship Care
              </Link>
            </p>
          </div>
          <div>
            <Heading size={3} className="color-lwb-theme-dark">
              Financial support
            </Heading>
            <p>
              As a Carer you will receive a tax-free allowance tailored to the
              age of the child and the complexity of their needs. Carers are
              volunteers, so the allowance is not considered income. During the
              process of becoming a carer we may talk with you about financial
              stability. The allowance carers receive is provided to cover the
              everyday costs of caring for a child, such as:
            </p>
            <ul className="mv4 pl1">
              {[
                "Food",
                "Clothing and footwear",
                "Daily travel expenses",
                "Car restraints",
                "Gifts",
                "Educational expenses",
                "Hobbies"
              ].map((text, i) => (
                <TickListItem
                  key={i}
                  text={text}
                  className={i === 0 ? "w-90-ns" : "mt3 w-90-ns"}
                  textClasses="pt0 pt1-ns"
                />
              ))}
            </ul>
            <ReadMoreLink
              text="Learn more"
              href="https://www.qld.gov.au/community/caring-child/foster-kinship-care/information-for-carers/money-matters/carer-allowances"
            />
          </div>
          <div>
            <Heading size={3} className="color-lwb-theme-dark">
              Carer networking
            </Heading>
            <p>
              Talking with other foster carers is sometimes the best form of
              support. Through regular events and online communities we create
              opportunities for carers like you to share concerns, celebrate
              successes and provide feedback to the Life Without Barriers team.
            </p>
          </div>
        </div>
      </Box>
    </Row>
  </Container>
)

export default QldWhatCanIExpect
