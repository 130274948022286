import React from "react"
import {
  Banner,
  Helmet,
  ContactUsPhoneLink,
  ContactUsEmailLink,
  SocialMediaLinks,
  Section,
  Container,
  Row,
  Box,
  Panel,
  Heading,
  SectionBanner,
  PreStyledComponents,
  Theme,
  ReadMoreLink,
  Link,
  IconKinship,
  IconEmergency,
  IconShortTerm,
  IconLongTerm,
  IconRespite,
  IconGuardianship,
  IconAdoption,
  IconTick
} from "@life-without-barriers/react-components"
import { graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { getGatsbyImage } from "@life-without-barriers/gatsby-common"

import NswWhatCanIExpect from "../../components/foster-care/StateLandingPage/NswWhatCanIExpect"
import VicWhatCanIExpect from "../../components/foster-care/StateLandingPage/VicWhatCanIExpect"
import QldWhatCanIExpect from "../../components/foster-care/StateLandingPage/QldWhatCanIExpect"
import SaWhatCanIExpect from "../../components/foster-care/StateLandingPage/SaWhatCanIExpect"
import WaWhatCanIExpect from "../../components/foster-care/StateLandingPage/WaWhatCanIExpect"
import TasWhatCanIExpect from "../../components/foster-care/StateLandingPage/TasWhatCanIExpect"
import Layout from "../../components/foster-care/Layout"
import { FosterCareForm } from "@life-without-barriers/shared-contact-form"
import { Site } from "../../contentTypes"

interface PageData {
  slug: string
  state: string
  stateShort: string
  typesOfCare: TypesOfCare[]
  typesOfCareSummary: string[]
  boxesToTick: BoxesToTick[]
  thingsToConsider: BoxesToTick[]
  wwcc: WorkingWithChildrenCheck
}

interface WorkingWithChildrenCheck {
  title: string
  blurb: string
  link?: string
}

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    whyBeACarer: IGatsbyImageData
    couldBeACarer: IGatsbyImageData
    page: PageData
  }
}

const { LinkButton, IconWrapper } = PreStyledComponents
const { fosterCareThemeFull } = Theme

interface TypesOfCare {
  title: string
  icon: string
  url: string
}

interface TypesOfCareProps {
  cares: TypesOfCare[]
}

const iconSelector = (type: string, color: string, height?: string) => {
  if (type === "kinship") {
    return <IconKinship color={color} height={height} />
  }
  if (type === "emergency") {
    return <IconEmergency color={color} height={height} />
  }
  if (type === "shortterm") {
    return <IconShortTerm color={color} height={height} />
  }
  if (type === "longterm") {
    return <IconLongTerm color={color} height={height} />
  }
  if (type === "respite") {
    return <IconRespite color={color} height={height} />
  }
  if (type === "guardianship") {
    return <IconGuardianship color={color} height={height} />
  }
  if (type === "adoption") {
    return <IconAdoption color={color} height={height} />
  }
  return undefined
}

const TypesOfCare = ({ cares }: TypesOfCareProps) => (
  <>
    {cares.map(({ title, icon, url }) => (
      <Box key={title} className="w-100 w-50-m w-third-l pv3 flex items-center">
        <div className="dn-l">
          <IconWrapper size="55" color={fosterCareThemeFull.xxxlight}>
            {iconSelector(icon, fosterCareThemeFull.medium, "35")}
          </IconWrapper>
        </div>
        <div className="dn db-l">
          <IconWrapper size="80" color={fosterCareThemeFull.xxxlight}>
            {iconSelector(icon, fosterCareThemeFull.medium, "40")}
          </IconWrapper>
        </div>
        <Link to={url} className="ph3 ph4-l color-lwb-black">
          {title}
        </Link>
      </Box>
    ))}
  </>
)

interface BoxesToTick {
  title: string
  blurb: string
}

interface BoxesToTickProps {
  items: BoxesToTick[]
}

const BoxesToTick = ({ items }: BoxesToTickProps) => (
  <>
    {items.map(({ title, blurb }) => (
      <li key={title} className="pb4">
        <div className="flex">
          <IconWrapper
            role="presentation"
            className="IconWrapper mr3"
            size="30"
            color={fosterCareThemeFull.light}
          >
            <IconTick aria-hidden height="13" color="white" />
          </IconWrapper>
          <div>
            <h3 className="ts-display-5 fw8">{title}</h3>
            <p>{blurb}</p>
          </div>
        </div>
      </li>
    ))}
  </>
)

const whatCanIExpectSection = (slug: string) => {
  switch (slug) {
    case "/nsw/":
      return <NswWhatCanIExpect />
    case "/vic/":
      return <VicWhatCanIExpect />
    case "/qld/":
      return <QldWhatCanIExpect />
    case "/sa/":
      return <SaWhatCanIExpect />
    case "/wa/":
      return <WaWhatCanIExpect />
    case "/tas/":
      return <TasWhatCanIExpect />
    default:
      throw new Error(`whatCanIExpectSection state not found: ${slug}`)
  }
}

const StateLandingPageTemplate = ({
  location,
  data: {
    site: { siteMetadata },
    whyBeACarer,
    couldBeACarer,
    page
  }
}: Props) => {
  const {
    state,
    stateShort,
    typesOfCare,
    typesOfCareSummary,
    boxesToTick,
    thingsToConsider,
    slug,
    wwcc
  } = page

  return (
    <div>
      <Helmet
        title={`How to be a foster carer in ${stateShort} | ${siteMetadata.title}`}
        description={`There is a critical need to provide foster care in ${stateShort}. Fostering a child is about offering a safe and supportive home environment to a child. Enquire today`}
        image={whyBeACarer}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <Layout>
        <Banner title={`How to be a foster carer in ${state}`} />
        <Container>
          <Row className="flex-wrap justify-between-ns">
            <Box className="w-100 w-two-thirds-ns">
              <Heading size={2}>Why be a carer?</Heading>
              <p>
                There is currently a critical need to provide foster care in{" "}
                {state}.
              </p>
              <p>
                With almost 50,000 children across Australia who need care each
                year, fostering a child is primarily about offering a safe,
                supportive and nurturing home environment.
              </p>
              <p>
                Where you live can affect some of the criteria you need to tick
                off in order to become a carer in your state.
              </p>
            </Box>
            <Box className="w-100 w-25-ns">
              <GatsbyImage
                image={getGatsbyImage(whyBeACarer)}
                alt=""
                className="mt4 mt0-ns"
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box className="layout-readable">
                <Heading size={2}>Types of care in {state}</Heading>
                {typesOfCareSummary.map((s, index) => (
                  <p key={index}>{s}</p>
                ))}
              </Box>
            </Row>
            <Row topMargin="standard">
              <Box>
                <Panel panelLine background="bg-lwb-white">
                  <Row className="flex-wrap">
                    <TypesOfCare cares={typesOfCare} />
                    <Box className="w-100">
                      <ReadMoreLink
                        className="mt4"
                        text="Learn about all types of care"
                        href="/foster-care/types-of-foster-care/"
                      />
                    </Box>
                  </Row>
                </Panel>
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row className="flex-wrap">
            <Box className="layout-readable">
              <Heading size={2}>
                If you want to be a foster carer in {state}, there are some
                boxes you need to tick.
              </Heading>
            </Box>
            <Box>
              <ul className="pv4 pl0 list layout-columns">
                <BoxesToTick items={boxesToTick} />
              </ul>
            </Box>
            <Box className="layout-readable">
              <p>
                <small>
                  <strong>*{wwcc.title}</strong> {wwcc.blurb}
                </small>
              </p>
              {wwcc.link && (
                <ReadMoreLink
                  className="pt3"
                  text="Find out more"
                  href={wwcc.link}
                />
              )}
            </Box>
            {thingsToConsider && (
              <>
                <Box className="layout-readable">
                  <Heading size={2} topMargin="standard">
                    When deciding if the time is right to embark on your
                    fostering journey, there are some things you might like to
                    consider
                  </Heading>
                </Box>
                <Box>
                  <div className="mt4 layout-columns">
                    {thingsToConsider.map(({ title, blurb }, index) => (
                      <div key={index} className="layout-gap dont-break mb4">
                        <Heading
                          size={3}
                          topMargin="none"
                          className="color-lwb-theme-dark"
                        >
                          {title}
                        </Heading>
                        <p>{blurb}</p>
                      </div>
                    ))}
                  </div>
                </Box>
              </>
            )}
          </Row>
        </Container>
        <SectionBanner alignRight={true} backgroundImage={couldBeACarer}>
          <Heading size={2}>Could you be a carer?</Heading>
          <p>
            Take the foster care self-assessment. It only takes minutes to find
            out if foster care is something you could do.
          </p>
          <LinkButton
            to="/foster-care/can-i-be-a-carer/self-assessment/"
            title="Take the quiz"
            className="mt4 mr2-ns tc fw8"
            state={{
              returnUrl: `/foster-care${slug}`
            }}
          >
            Take the quiz
          </LinkButton>
        </SectionBanner>
        {whatCanIExpectSection(slug)}
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row className="flex-wrap justify-between-l">
              <Box className="w-100 w-50-l">
                <Heading size={2} className="title ts-display-3 fw8">
                  Got more questions? Get in touch today
                </Heading>
                <div className="mt2 flex flex-row flex-wrap pv3">
                  <ContactUsPhoneLink
                    marginClasses="w-100 w-50-l mv2"
                    phoneNumber="1300 592 227"
                    styles={{ iconBackgroundColor: "#fff" }}
                  />
                  <ContactUsEmailLink
                    marginClasses="w-100 w-50-l mv2"
                    text="carers@lwb.org.au"
                    link="mailto:carers@lwb.org.au"
                    styles={{ iconBackgroundColor: "#FFF" }}
                  />
                </div>
                <p>
                  Life Without Barriers is committed to providing safe,
                  supportive and respectful environments for children, so that
                  they can thrive and be happy.
                </p>
                <p>
                  If you would like to learn more about how to become a foster
                  carer in {state}, our team is ready to answer your questions.
                </p>
                <p>
                  You can contact us today and we will come back to you as soon
                  as possible.
                </p>
                <div className={"pt4"}>
                  <SocialMediaLinks styles={{ iconBackgroundColor: "#FFF" }} />
                </div>
              </Box>
              <Box className="w-100 w-34-l mt4 mt0-l">
                <FosterCareForm
                  formId={`foster-care-${stateShort.toLowerCase()}`}
                />
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($slug: String) {
    page: stateLandingPageJson(slug: { eq: $slug }) {
      slug
      stateShort
      state
      typesOfCareSummary
      typesOfCare {
        icon
        title
        url
      }
      boxesToTick {
        title
        blurb
      }
      thingsToConsider {
        title
        blurb
      }
      wwcc {
        title
        blurb
        link
      }
    }
    whyBeACarer: file(relativePath: { regex: "/lwb-happy-child.jpg/" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, width: 1200)
      }
    }
    couldBeACarer: file(relativePath: { regex: "/lwb-learning-to-ride.jpg/" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, width: 1200)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default StateLandingPageTemplate
