import React from "react"
import {
  Container,
  Heading,
  Row,
  Box,
  ReadMoreLink,
  TickListItem
} from "@life-without-barriers/react-components"

const SaWhatCanIExpect = () => (
  <Container>
    <Row>
      <Box className="layout-readable">
        <Heading size={2}>What can I expect as a carer?</Heading>
        <p>
          Fostering a child in South Australia with Life Without Barriers means
          you will receive <b>training, help and support</b> from day one of
          your journey.
        </p>
      </Box>
    </Row>
    <Row>
      <Box>
        <div className="mt4 layout-columns">
          <div>
            <Heading size={3} topMargin="none" className="color-lwb-theme-dark">
              Training
            </Heading>
            <p>
              Life Without Barriers will provide you with a range of learning
              opportunities suited to your own level of experience and the needs
              of children in your care. This includes access to a large range of
              eLearning options on topics such as child development, Therapeutic
              parenting support, linking with other carers, nurturing and
              attachment relationships, understanding trauma and stress, and
              healthy living.
            </p>
          </div>
          <div>
            <Heading size={3} className="color-lwb-theme-dark">
              Support
            </Heading>
            <p>
              Fostering children in South Australia with Life Without Barriers
              means you are never alone in your journey. It can be difficult to
              understand some of the experiences children bring with them, so
              it’s important that as a carer you feel supported and confident in
              your role. You will have access to 24/7 support for advice and
              direction in challenging situations. Also, our specialist staff
              work with children and carers where additional support is needed.
            </p>
          </div>
          <div>
            <Heading size={3} className="color-lwb-theme-dark">
              MOCKINGBIRD FAMILY™
            </Heading>
            <p>
              MOCKINGBIRD FAMILY™ unites 6 to 10 foster and/or kinship carer
              homes in a local community, supported by an experienced carer who
              takes on the role of Hub Home Provider.
            </p>
            <p>
              The Hub Home Provider is an employed team member who supports
              carers through providing connection, information, training and
              emotional support.
            </p>
            <p>
              For the children, the Hub Home is like a grandparent’s house that
              is familiar and comfortable. Each child is welcomed by the Hub
              Home Provider for sleepovers and social events, or if there is
              difficulty at home and the child and/or carers could benefit from
              a short break.
            </p>
          </div>
          <div>
            <Heading size={3} className="color-lwb-theme-dark">
              Financial support
            </Heading>
            <p>
              As a carer you will receive a tax-free allowance to support the
              individual needs of children placed in your care. Foster carers
              are volunteers, so this is not considered income and all potential
              carers need to show they are financially stable when they start
              their fostering journey. It is important to note that funds
              provided are for items that the child in your care will need, such
              as:
            </p>
            <ul className="mv4 pl1">
              {[
                "Food",
                "Clothing and footwear",
                "Daily travel expenses",
                "Car restraints",
                "Gifts",
                "Educational expenses",
                "Hobbies"
              ].map((text, i) => (
                <TickListItem
                  key={i}
                  text={text}
                  className={i === 0 ? "w-90-ns" : "mt3 w-90-ns"}
                  textClasses="pt0 pt1-ns"
                />
              ))}
            </ul>
            <ReadMoreLink
              text="Learn more"
              href="https://www.childprotection.sa.gov.au/carers/how-dcp-works/carer-support-payments"
            />
          </div>
          <div>
            <Heading size={3} className="color-lwb-theme-dark">
              Carer networking
            </Heading>
            <p>
              Talking with other foster carers is sometimes the best form of
              support. Through regular events and online communities we create
              opportunities for carers like you to share concerns, celebrate
              successes and provide feedback to the Life Without Barriers team.
              Training programs also offer a great way to connect with other
              carers of varying experience.{" "}
            </p>
          </div>
        </div>
      </Box>
    </Row>
  </Container>
)

export default SaWhatCanIExpect
